.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #353535;
    margin: 7px;
    border: 1px solid #767676;
    border-radius: 5px;
    width: 75%;
    cursor: pointer;
    transition: background 0.15s;
}

.container:hover {
    background: #4c4c4c;
}

.containerDisabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #353535;
    margin: 7px;
    border: 1px solid #767676;
    border-radius: 5px;
    width: 75%;
    opacity: 0.3;
}

.icon {
    width: 35px;
}

.text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px; 
    margin: 18px;
    color: white
}