.container {
    width: calc(100% - 100px);
    max-width: 1440px;
    align-self: center;
    height: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 50px;
}

.goatIconContainer {
    flex: 1;
}

.goatIcon {
    height: 73.01px;
}

.icon {
    width: 26px;
    min-width: 26px;
    margin-right: 35px;
    cursor: pointer;
    fill: #D9B76D;
    transition: fill 0.15s;
}

.icon:hover {
    fill: #d4c9b1;
}

@media only screen and (max-width: 615px) {
    .container {
        width: calc(100% - 48px);
        padding: 0 24px;
        height: 100px;
    }

    .icon {
        display: none;
    }

    .goatIcon {
        height: 47px;
    }

    .labeledIcon {
        padding: 3px;
        height: 35px;
    }

    .metamaskIcon {
        width: 33px;
        height: 33px;
    }

}