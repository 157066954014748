.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #D9B76D;
    border-radius: 100px;
    cursor: pointer;
    transition: background 0.15s;
}

.button:hover {
    background-color: #d4c9b1;
}

.text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 14px 20px;
}

.textDisabled {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    margin: 14px 20px;
    color: #5e4f2e;
}

.buttonDisabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    background: #D9B76D1F;
    border-radius: 100px;
}

.loader {
    width: 25px;
    margin-left: 20px;
}