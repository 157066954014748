.container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    background: #4D4945;
}

.inputContainer {
    z-index: 1;
    padding: 20px;
    background: #000000;
    border: 4px solid #d9b76d4d;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.15s;
}

.message {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #d9b76de0;
    margin: 0 10px;
}