.container {
    width: min(510px, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1B1B1B;
    border-radius: 10px;
}

.closeContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.closeIcon {
    stroke: #D9B76D;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: fill 0.15s;
    margin: 30px 30px 5px 0;
}

.closeIcon:hover {
    stroke: white;
}

.title {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    margin-top: 35px;
}

.message {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    max-width: 400px;
    padding: 0 10px;
}

.button {
    width: 155px;
    height: 45px;
    margin: 20px 0 35px 0;
}
