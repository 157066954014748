.container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.container:hover {
    background-color: #D9B76D19;
}

.containerDisabled {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
}

.icon {
    width: 80%;
    stroke: white;
}

.iconDisabled {
    width: 80%;
    stroke: #2f2f2f;
}