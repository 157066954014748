.topContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.gradientCover {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, #1D1C1D 0%, #00000000 40%, #00000000 100%);
}

.centerContentContainer {
    position: relative;
    width: calc(100% - 100px);
    max-width: 1185px;
    height: 100%;
    margin: 0 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.textContainer {
    margin-right: 110px;
    margin-bottom: 110px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.title {
    width: 500px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 36px;
    color: #FFFFFF;
    margin: 16px 0;
}

.description {
    width: 320px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #989898;
    margin: 0;
}

.minted {
    width: 320px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #b5b5b5;
    margin: 25px 0 0 0;
}

.goatImageContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex: 1;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.goatImage {
    height: 90%;
}

.bottomContainer {
    width: 100%;
    height: 300px;
}

.centerContentContainerMobile {
    display: none;
    flex-direction: column;
    width: 100%;
}

.textContainerMobile {
    display: flex;
    flex-direction: column;
    margin: 140px 50px 0 50px;
}

.goatImageContainerMobile {
    position: relative;
    margin-top: 40px;
}

.goatImageInnerContainerMobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.goatImageMobile {
    width: 100%;
}

@media only screen and (max-width: 830px) {
    .centerContentContainerMobile {
        display: flex;
    }
    
    .centerContentContainer {
        overflow: hidden;
        height: 0;
    }

    .title {
        font-size: 28px;
        line-height: 28px;
        width: 350px;
    }

    .description {
        font-size: 13px;
        line-height: 21px;
        width: 300px;
        margin-right: 50px;
    }
}


@media only screen and (max-width: 615px) {
    .textContainerMobile {
        display: flex;
        flex-direction: column;
        margin: 130px 30px 0 30px;
    }
}


@media only screen and (max-width: 420px) {
    .topContainer {
        zoom: 0.9;
        height: calc(100vh / 0.9);
    }
}

@media only screen and (max-width: 365px) {
    .topContainer {
        zoom: 0.8;
        height: calc(100vh / 0.8);
    }
}

@media only screen and (max-width: 325px) {
    .topContainer {
        zoom: 0.7;
        height: calc(100vh / 0.7);
    }
}

@media only screen and (max-width: 290px) {
    .topContainer {
        zoom: 0.6;
        height: calc(100vh / 0.6);
    }
}

@media only screen and (max-height: 820px) {
    .textContainerMobile {
        margin: 100px 30px 0 30px;
    }
}