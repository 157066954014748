.container {
    z-index: 100;
    position: fixed;
    background-color: #00000099;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.menuContainer {
    background-color: #292A2E;
    width: 615px;
    height: 100%;
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
}

.topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topBarText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.closeIcon {
    width: 32px;
    height: 32px;
    stroke: #D9B76D;
    margin-left: 12px;
    cursor: pointer;
    transition: stroke 0.15s;
}

.closeIcon:hover {
    stroke: white;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    width: 250px;
    margin: 50px 0 90px 0;
}

.itemContainer {
    width: 100%;
    flex: 1;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #4D4D4D;
    overflow-y: auto;
    padding-right: 2px;
}

@media only screen and (max-width: 615px) {
    .menuContainer {
        width: calc(100% - 50px);
        padding: 20px 25px;
    }
    
    .topBarText {
        font-size: 15px;
        line-height: 24px;
    }

    .closeIcon {
        width: 30px;
        height: 30px;
    }

    .title {
        font-size: 28px;
        line-height: 28px;
        margin: 40px 0 60px 0;
    }
}

@media only screen and (max-width: 380px) {
    .container {
        zoom: 0.9;
    }
}

@media only screen and (max-width: 320px) {
    .container {
        zoom: 0.8;
    }
}

@media only screen and (max-width: 290px) {
    .container {
        zoom: 0.7;
    }
}