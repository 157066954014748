.container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #4D4D4D;
}

.topBar {
    display: flex;
    flex-direction: row;
    transition: 0.15s;
}

.expandedTopBar {
    background: #D9B76D;
}

.number {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    opacity: 0.5;
    margin: 27px;
    width: 36px;
}

.expandedNumber {
    opacity: 1;
}

.itemTitle {
    flex: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.expandIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.expandIcon {
    width: 32px;
    height: 32px;
    stroke: white;
    margin: 20px;
    transition: stroke 0.15s;
}

.expandIcon:hover {
    stroke: #9b834e;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    margin: 18px 90px 24px 90px;
    overflow: hidden;
    transition: 0.15s ease;
}

.subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 7px 0;
}

.content {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #FFFFFF;
    margin: 7px 0;
}

.contentHidden {
    max-height: 0;
    opacity: 0;
    margin: 0 90px;
}


@media only screen and (max-width: 615px) {
    .itemTitle {
        font-size: 14px;
        line-height: 14px;
    }

    .number {
        font-size: 14px;
        line-height: 14px;
        margin: 15px;
        width: 20px;
    }

    .expandIcon {
        width: 25px;
        height: 25px;
    }

    .contentContainer {
        margin: 10px 50px 15px 50px;
    }

    .contentHidden {
        margin: 0 50px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 21px;
        margin: 5px 0;
    }
    
    .content {
        font-size: 12px;
        line-height: 21px;
        margin: 3px 0;
    }
}