.container {
    width: calc(100% - 100px);
    max-width: 1440px;
    align-self: center;
    height: 105px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    margin-bottom: 20px;
}

.splitter {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.splitterStart {
    justify-content: flex-start;
}

.splitterCenter {
    justify-content: center;
}

.splitterEnd {
    justify-content: flex-end
}

.iconButton {
    display: none;
    background-color: black;
}

.roadmapIcon {
    width: 22px;
}

.openseaIcon {
    width: 24px;
    stroke: none;
}

@media only screen and (max-width: 830px) {
    .container {
        width: calc(100% - 48px);
        height: 105px;
        padding: 0 28px;
    }

    .labeledIcon {
        display: none !important;
    }

    .iconButton {
        display: flex;
    }

    .splitter {
        flex: none !important;
    }

    .splitterCenter {
        flex: 1;
    }
}