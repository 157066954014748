.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    background: #D9B76D19;
    border-radius: 100px;
    height: 39px;
    padding: 7px;
    cursor: pointer;
    transition: 0.15s;
}

.container:hover {
    background: #D9B76D25;
}

.containerDisabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    background: #D9B76D19;
    border-radius: 100px;
    height: 39px;
    padding: 7px;
    opacity: 0.6;
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 0 18px;
}

.iconContainer {
    width: 39px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-radius: 50%;
}

.icon {
    width: 60%;
}