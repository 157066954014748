.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.countText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 0 10px 0 20px;
    text-align: right;
}

.ethereumIcon {
    height: 30px;
    fill: white;
    margin-right: 10px;
}